import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Banner.module.scss"

const Banner = ({ strapiData }) => {
  // const image = getImage(strapiData?.secImages[0]?.localFile)

  return (
    <div className={styles.banner}>
      <Container>
        <Row>
          <Col lg={6} md={12}>
            <div className={styles.heading}>
              <p className={styles.mainTitle}>
                Software Development For Startups
              </p>
              <h1
                className={styles.bannerHeading}
                // dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              >
                Driving Your <span>Product Forward</span>
              </h1>
              <p className={styles.description}>
                We’re built for startups—flexible, adaptable, and ready to scale
                with you. From early growth to major milestones, we’re here to
                fuel your journey every step of the way.
              </p>
            </div>
            <div className={styles.btns}>
              <div className={`${styles.newBtn}`}>
                {strapiData?.buttons[0] && (
                  <Link to={strapiData?.buttons[0]?.url}>
                    <p>Got An Idea</p>
                  </Link>
                )}
              </div>
              <div className={`${styles.newBtn2}`}>
                {strapiData?.buttons[0] && (
                  <Link to={strapiData?.buttons[0]?.url}>
                    <p>Assemble Team</p>
                  </Link>
                )}
              </div>
            </div>
          </Col>
          {/* <Col lg={6} md={12} className={styles.bnrImg}>
            {image ? (
              <GatsbyImage
                placeholder="blurred"
                decoding="async"
                loading="lazy"
                image={image}
                alt={strapiData?.title}
              />
            ) : (
              <img
                src={strapiData?.secImages[0]?.localFile?.publicURL}
                alt={strapiData?.title}
                decoding="async"
                loading="lazy"
                className={`${styles.BannerImage} `}
              />
            )}
          </Col> */}
        </Row>
      </Container>
    </div>
  )
}

export default Banner
