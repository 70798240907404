// extracted by mini-css-extract-plugin
export var arrowAnimation = "Qickly-module--arrowAnimation--8c463";
export var black = "Qickly-module--black--af755";
export var card = "Qickly-module--card--4a909";
export var card1 = "Qickly-module--card1--ff56d";
export var card2 = "Qickly-module--card2--7717a";
export var card3 = "Qickly-module--card3--50d7c";
export var colGap = "Qickly-module--colGap--c1ce5";
export var concerns = "Qickly-module--concerns--0c264";
export var description = "Qickly-module--description--91c41";
export var heading = "Qickly-module--heading--9b9a8";
export var newBtn = "Qickly-module--newBtn--1b0f2";
export var progress = "Qickly-module--progress--79eee";
export var progressSec = "Qickly-module--progressSec--0d58b";
export var subDescription = "Qickly-module--subDescription--be466";
export var subHeading = "Qickly-module--subHeading--f2333";
export var talkBtn = "Qickly-module--talkBtn--a4e49";
export var white = "Qickly-module--white--1193e";