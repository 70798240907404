import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Models.module.scss"



const Models = ({ strapiData }) => {
  return (
    <div className={styles.progressSec}>
      <Container>
        <div className={styles.progress}>
          <h2 className={styles.heading}>
            Backing Your Up At <span>Every Stage of your Progress</span>
          </h2>
          <p className={styles.subDescription}>
            From launching startups to expanding enterprises, or recovering from
            setbacks, we help businesses overcome every challenge on their
            journey.
          </p>
          <Row className="gap-30">
            {strapiData?.cards?.map((item, index) => (
              <Col
                xl={4}
                lg={6}
                md={6}
                sm={12}
                key={index}
                className={`${styles.colGap} d-flex`}
              >
                <div className={`${styles.card} ${styles[`card${index + 1}`]}`}>
                  <h3 className={styles.subHeading}>{item.title}</h3>
                  <p
                    className={`${styles.description} ${
                      styles[item.textColor]
                    }`}
                  >
                    {item.subTitle}
                  </p>
                  
                 <div>
                 <Link
                    to={item?.link}
                    className={` ${styles[item.textColor]} ${styles.newBtn}`}
                  >
                   Learn More
                  </Link>
                 </div>
                  {/* <Link
                    to={item?.link}
                    className={` ${styles[item.textColor]} ${styles.newBtn}`}
                  >
                   Learn More
                  </Link> */}
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default Models
