// extracted by mini-css-extract-plugin
export var arrowAnimation = "Models-module--arrowAnimation--8d8b4";
export var black = "Models-module--black--6e614";
export var card = "Models-module--card--28fda";
export var colGap = "Models-module--colGap--67591";
export var description = "Models-module--description--f2940";
export var heading = "Models-module--heading--376af";
export var newBtn = "Models-module--newBtn--453cc";
export var progress = "Models-module--progress--bf1fc";
export var progressSec = "Models-module--progressSec--99cb7";
export var subDescription = "Models-module--subDescription--2b4b3";
export var subHeading = "Models-module--subHeading--02908";
export var white = "Models-module--white--6c2ac";