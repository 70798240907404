import React from "react"
import * as styles from "./HorizonMobile.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import { Link } from "gatsby"
// import "./Clients.scss"
import Slider from "react-slick"


const Journey = ({ strapiData, projectEngRTest }) => {

  const data = [
    {
      name: "Product Discovery Workshop",
      img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1707478877_09b7138d6c.png",
      skills: [
        "Define project goals and user needs with stakeholders.",
        "Identify key challenges and features through brainstorming.",
        "Validate ideas and prioritize features based on feedback.",
        "Set clear metrics to track progress and success.",
      ],
    },
    {
      img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1707478941_0dc6a9166c.svg",
      name: "MVP Development for Startups",
      skills: [
        "Build an MVP with essential features for a quick launch.",
        "Launch within three months for fast user feedback.",
        "Refine products based on real-world insights.",
        "Prepare a polished MVP for investor presentations.",
      ],
    },
    {
      img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1707478942_f9550503be.svg",
      name: "Fractional CTO",
      skills: [
        "Lead strategy and tech direction on a part-time basis.",
        "Advise on product development and tech stack choices.",
        "Scale teams and optimize technical processes.",
        "Guide fundraising and infrastructure growth.",
      ],
    },
    {
      img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1707478943_993e76d8d7.svg",
      name: "Technology Stack Selection",
      skills: [
        "Choose technologies based on product needs and goals.",
        "Prioritize scalability, security, and maintainability.",
        "Ensure smooth integration with the product architecture.",
        "Select tools for rapid prototyping and iteration.",
      ],
    },
    {
      img: "",
      name: "Teams as a Service",
      skills: [
        "Work in short cycles for fast delivery and feedback.",
        "Integrate quality assurance into each sprint.",
        "Adapt features based on real-time insights.",
        "Improve continuously through iterative testing.",
      ],
    },
    {
      img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1707478944_3d9148df21.svg",
      name: "Support and Maintenance",
      skills: [
        "Get ongoing support for smooth operations.",
        "Regularly update and fix bugs.",
        "Monitor and optimize system performance.",
        "Prevent issues with proactive maintenance.",
      ],
    },
  ]

  const settings = {
    infinite: true,
    autoplay: false,
    slidesToShow: 2,
    dotsClass: "new-dot-python",
    centerPadding: "0px",
    slidesToScroll: 3,
    arrows: true,
    responsive: [
      {
        breakpoint: 1220,
        settings: {
          slidesToShow: 3,
          arrows: false,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          arrows: false,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
          // dotsClass: "new-dot-python",
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
          // dotsClass: "new-dot-python",
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <div className={styles.Trust}>
      <React.Fragment>
        <Container>
          <h2 className={styles.heading}>
            <span>We Connect With Startups</span> At Every Step Of Their Journey
          </h2>
          <Row className="align-items-center clientSlider">
            <Slider {...settings} className={styles.SliderWidth}>
              {data?.map((el, i) => (
                <div key={i} className={styles.slideWrapper}>
                  <div
                    className={`${styles.cards} ${
                      i % 2 === 0 ? styles.greyCard : styles.redCard
                    } ${styles.cardHover}`}
                  >
                    <img src={el?.image2} className={styles.image2} />
                    <div className={styles.cardContent}>
                      <h3>{el?.name}</h3>
                      <p className={styles.dec}>{el?.description}</p>
                      <div className={styles.points}>
                        {el.skills &&
                          el.skills.map((skill, eIdx) => (
                            <div key={eIdx} className={styles.arrowPoint}>
                              <img
                                src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1707478916_83a96a0f17.svg"
                                decoding="async"
                                loading="lazy"
                                alt=""
                              />
                              <p className={`${styles.skill}`}>{skill}</p>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </Row>
        </Container>
      </React.Fragment>
    </div>
  )
}

export default Journey
