import React from "react"
import Col from "react-bootstrap/Col"
import { Link } from "gatsby"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Steps.module.scss"

const cardData = [
  {
    title: "Book A Call",
    description:
      "Explore ideas, solve challenges, or just brainstorm Book a call with us and let’s turn your vision into a reality.  ",
    imgage:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/fi_1067566_40ca08222d.png",
  },
  {
    title: "Meet The Team",
    description:
      "After the successful call, we can have you meet our team. Get to know the faces behind all the hard work.",
    imgage:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/fi_1067566_40ca08222d.png",
  },
  {
    title: "Get A Roadmap",
    description:
      "Now, we create a very detailed roadmap of all the project stages. Get a custom-made plan that shows you the journey ahead. ",
    imgage:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/fi_1067566_40ca08222d.png",
  },
]

function Steps() {
  return (
    <div className={styles.steps}>
      <Container>
        <Row className="justify-content-between align-items-center">
          <h2>
            What Are The <span>Next Steps?</span>
          </h2>
          <div className={`${styles.newBtn}`}>
            <Link to={"/contact-us/"}>
              <p>Get Started</p>
            </Link>
          </div>
        </Row>
        <Row>
          {cardData?.map((item, i) => (
            <Col lg={4} md={6} sm={12} className={`${styles.calPad} d-flex`}>
              <div className={styles.cards}>
              <span className={styles.num}>Step 0{i+1}</span>
                <img src={item?.imgage} />
                <h3>{item?.title}</h3>
                <p>{item?.description}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default Steps
